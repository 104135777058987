import React, { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import routes from 'routes.js'

// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react'

// Layout components
import { SidebarContext } from 'contexts/SidebarContext'

// Custom Chakra theme
export default function Auth() {
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false)
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/auth/full-screen-maps'
  }
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/payment') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      }
      if (prop.collapse) {
        return getRoutes(prop.items)
      }
      if (prop.category) {
        return getRoutes(prop.items)
      } else {
        return null
      }
    })
  }
  const authBg = "#eef1fa;"
  document.documentElement.dir = 'ltr'
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar
        }}
      >
        <Box
          bg={authBg}
          float='right'
          minHeight='100vh'
          height='100%'
          position='relative'
          w='100%'
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'
        >
          {getRoute() ? (
            <Box mx='auto' minH='100vh'>
              <Switch>
                {getRoutes(routes)}
                <Redirect
                  from='/auth'
                  to='/auth/signin
                  '
                />
              </Switch>
            </Box>
          ) : null}
        </Box>
      </SidebarContext.Provider>
    </Box>
  )
}
