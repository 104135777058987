import {
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";


const Reward = () => {
 
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid>
        <Card px='0px' mb='20px'>
          
        </Card>
      </SimpleGrid>
    </Box>
  )
}

export default Reward;